import { Get } from 'config/apiServiceConfig'
import axiosInstanceApiGateway from 'config/apiServiceApiGateway'
import { OrderItemType } from '../redux/AllOrder'
import { CommonNameValueObjectType, OrderV2ResponseType } from '../redux/AllOrder_V2'

export type OrderTypesItemType = 'INSTANT' | 'GRAB_MART' | 'SUPER' | 'KITCHEN' | 'VENDING'

export type OrderType = OrderTypesItemType[]

export type AllOrderResponseType = {
  content: OrderItemType[]
  empty: boolean
  first: boolean
  last: boolean
  number: number
  numberOfElements: number
  pageable: {
    sort: {
      sorted: boolean
      unsorted: boolean
      empty: boolean
    }
    offset: number
    pageSize: number
    pageNumber: number
    paged: boolean
    unpaged: boolean
  }
  size: number
  sort: {
    sorted: boolean
    unsorted: boolean
    empty: boolean
  }
}

export type OrderLocationType = {
  location_id: number
  location_name: string
  location_type: string
}

export type OrderLocationResponseType = {
  data: OrderLocationType[]
  error: {
    status: boolean
    message: string
    code: number
  }
  pagination: null
}

type FilterOrderResponseType = {
  order_types: CommonNameValueObjectType[]
  order_status: CommonNameValueObjectType[]
  slas: CommonNameValueObjectType[]
  locations: CommonNameValueObjectType[]
}

export const getOrderTypes = () => Get<{ data: OrderType }>({ url: '/api/order/types' })

export const getAllOrder = (query: string) =>
  Get<AllOrderResponseType>({ url: `/api/order${query}` })

export const getOrderLocations = () =>
  Get<OrderLocationResponseType>({ url: `/api/locations/order` })

export const getAllOrderV2API = (query: string) =>
  axiosInstanceApiGateway.Get<OrderV2ResponseType>({ url: `/oms/internal/v2/orders${query}` })
export const getAllFiltersV2 = () =>
  axiosInstanceApiGateway.Get<FilterOrderResponseType>({
    url: '/oms/internal/v2/orders/filters',
  })

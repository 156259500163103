import { createUseRBAC } from 'features/Auth/utils/hooks/useRBAC'

export const MasterApprovalRBAC = {
  pageID: 'd32ee4351f14cbed389a8fe99b8f327a',
  elementID: {
    ViewApprovalList: 'eaeaf0577b9f0bdbb32b6b288abdae94',
    SubmitApprovalAsApprover: 'd0fff64785c71bc0f70699033437c6a8',
    SubmitApprovalAsRequester: '1c31926c09ff26d4865ae5e1b62731f7',
    SubmitApprovalAsHR: '5a7aaae402b80eb44e65d6e75dd113ad',
  },
} as const

export const useRBACMasterApprovalPage = createUseRBAC(MasterApprovalRBAC.elementID)

export type UseRBACMasterApprovalPageType = ReturnType<typeof useRBACMasterApprovalPage>

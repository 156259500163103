import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { withLoadingReducer } from 'utils/reducerHandler'
import {
  fetchAllOrderV2,
  fetchAllOrderV2Filter,
  OrderV2FilterType,
  OrderV2ResponseType,
} from './thunk'
// import { withLoadingReducer } from 'utils/reducerHandler'

type InitialStateType = {
  isLoading: boolean
  dialogState:
    | 'RETURN_VERIFICATION_DIALOG'
    | 'ON_HOLD_DIALOG'
    | 'SKIP_VERIFICATION_PACKAGE_DIALOG'
    | 'SKIP_VERIFICATION_LOCATION_DIALOG'
    | 'COMPLETE_ORDER_DIALOG'
    | 'REDELIVERY_DIALOG'
    | 'EDIT_ADDRESS_DIALOG'
    | 'CANCEL_ORDER_DIALOG'
    | 'CLOSED'
  popUpSkipDropOff: 'SKIP_VERIFICATION_DROP_OFF' | 'CLOSED'
  orderList: OrderV2ResponseType
  selectedOrder: OrderV2ResponseType[number] | ObjectNullType
  pagination: {
    firstOrderID: number
    lastOrderID: number
    pageIndex: number
  }
  filters: OrderV2FilterType
}

const initialState: InitialStateType = {
  isLoading: false,
  dialogState: 'CLOSED',
  popUpSkipDropOff: 'CLOSED',
  orderList: [],
  selectedOrder: {},
  pagination: {
    firstOrderID: 0,
    lastOrderID: 0,
    pageIndex: 1,
  },
  filters: {
    order_status: [],
    order_types: [],
    slas: [],
    locations: [],
  },
}

const allOrderV2Slice = createSlice({
  name: 'allOrderV2',
  initialState,
  reducers: {
    setResetOrder: () => ({
      ...initialState,
      orderList: [],
    }),
    setDialogState: (state, action: PayloadAction<InitialStateType['dialogState']>) => {
      state.dialogState = action.payload
    },
    setSelectedOrderData: (state, action: PayloadAction<InitialStateType['selectedOrder']>) => {
      state.selectedOrder = action.payload
    },
    setPopUpSkipDropOff: (state, action: PayloadAction<InitialStateType['popUpSkipDropOff']>) => {
      state.popUpSkipDropOff = action.payload
    },
    setPopUpDone: (state, action: PayloadAction<InitialStateType['popUpSkipDropOff']>) => {
      state.popUpSkipDropOff = action.payload
    },
  },
  extraReducers: withLoadingReducer(
    (builder: ActionReducerMapBuilder<InitialStateType>) => {
      builder.addCase(fetchAllOrderV2.fulfilled, (state, action) => {
        if (action.payload && action.payload.data) {
          const newLastElement = action.payload.data[action.payload.data.length - 1].order.order_id
          const previousFirstElement = state.orderList[0].order.order_id

          if (state.pagination.pageIndex === 1) {
            state.pagination.firstOrderID = 0
            state.pagination.lastOrderID = newLastElement
          } else {
            state.pagination.firstOrderID = previousFirstElement
            state.pagination.lastOrderID = newLastElement
          }

          // this pagination will produce bug if user copy paste link from url
          state.pagination.pageIndex = action.meta.arg.includes('next')
            ? state.pagination.pageIndex + 1
            : state.pagination.pageIndex - 1
          state.orderList = action.payload.data
        }
      })
      builder.addCase(fetchAllOrderV2Filter.fulfilled, (state, action) => {
        if (action.payload && action.payload.data) {
          state.filters = action.payload.data
        }
      })
    },
    [fetchAllOrderV2, fetchAllOrderV2Filter],
  ),
})
export const {
  setResetOrder,
  setDialogState,
  setSelectedOrderData,
  setPopUpSkipDropOff,
  setPopUpDone,
} = allOrderV2Slice.actions
export default allOrderV2Slice.reducer

/* eslint-disable no-undef */
import axios from 'axios'
import { Get, Put, Post, Delete } from 'config/apiServiceConfig'

import { objectToQueryParams } from 'utils/getParams'
import axiosInstanceApiGateway from 'config/apiServiceApiGateway'
import apiServiceApiGateway from 'config/apiServiceApiGateway'

const { REACT_APP_BASE_URL_API, REACT_APP_BASE_URL_API_CONSUMER } = process.env
const baseUrl = REACT_APP_BASE_URL_API
const consumerAPI = REACT_APP_BASE_URL_API_CONSUMER

const uninterceptedAxiosInstance = axios.create()
const postValidateInboundEndpoint = (isHubFeatureEdDryScanActive) =>
  `/api/${isHubFeatureEdDryScanActive ? 'v2/' : ''}inbound-grand/:id/validate`

export const getApi = (url, params) => axios.get(`${baseUrl}/api/${url}`, { params })

export const deleteSession = (headers) =>
  Delete({
    url: '/api/user/remove/session',
  })

export const postForgetPassword = (payload) =>
  axios.post(`${baseUrl}/api/request-forgot-password`, payload)

export const postUpdatePassword = (payload, header) =>
  uninterceptedAxiosInstance.post(`${baseUrl}/api/update-forgot-password`, payload, header)

export const getUserData = () => apiServiceApiGateway.Get({ url: '/accounts/internal/v1/whoami' })

// query appVersion={appVersion}&featureKey={featureKey}&userId={userId}
export const getFeatureFlagByLocationID = (locationId, query) =>
  axios.get(`${baseUrl}/api/feature-flag/${locationId}/1`, { params: query })

export const getHubFeature = (params) =>
  axios.get(`${baseUrl}/api/feature-flag/hub-feature`, { params })

export const getFeatureFlag = (locationId, featureFlag) =>
  axios.get(`${baseUrl}/api/feature-flag/${locationId}/1/${featureFlag}`)

export const getInventoryStatusReason = (query) =>
  axios.get(`${baseUrl}/api/inventory-status-notes${objectToQueryParams(query)}`)

export const postCategory = (payload) => axios.post(`${baseUrl}/api/category`, payload)

export const getCategories = (params) => axios.get(`${baseUrl}/api/category`, { params: params })

export const putCategory = (id, payload) => axios.put(`${baseUrl}/api/category/${id}`, payload)

export const getCategory = (id) => axios.get(`${baseUrl}/api/category/${id}`)

export const getExportedCategoryLink = () => axios.get(`${baseUrl}/api/category/csv/download`)

export const postBanner = (payload) => axios.post(`${baseUrl}/api/banner`, payload)

export const getBanners = (query = '') => axios.get(`${baseUrl}/api/banner${query}`)

export const putBanner = (id, payload) => axios.put(`${baseUrl}/api/banner/${id}`, payload)

export const putBannerActive = (id, payload) =>
  axios.put(`${baseUrl}/api/banner/${id}/active`, payload)

export const getBanner = (id) => axios.get(`${baseUrl}/api/banner/${id}`)

export const getAllCategories = () => axios.get(`${baseUrl}/api/categories`)

export const getLocations = (query) => axios.get(`${baseUrl}/api/location${query}`)

export const postLocation = (payload) => axios.post(`${baseUrl}/api/location`, payload)

export const putLocationActive = (id, payload) =>
  axios.put(`${baseUrl}/api/location/${id}/active`, payload)

export const getLocation = (id) => axios.get(`${baseUrl}/api/location/${id}`)

export const putLocation = (id, payload) => axios.put(`${baseUrl}/api/location/${id}`, payload)

export const getSupervisors = (type) => axios.get(`${baseUrl}/api/user/supervisor/${type}`)

export const getAllCategoriesChild = () => axios.get(`${baseUrl}/api/categories/child`)

export const postProduct = (data) =>
  Post({ url: '/api/ims/:version/products', version: 'v2', data })

export const getProducts = (query = '') => axios.get(`${baseUrl}/api/product${query}`)
export const getProductsOnBoM = (params = {}) =>
  Get({
    url: '/api/ims/:version/products',
    version: 'v2',
    params,
  })

export const getPpnList = (params) => axios.get(`${baseUrl}/api/ims/ppn/v1`, { params })

export const putUpdateProductStatus = (id, data) =>
  axiosInstanceApiGateway.Put({ url: `/ims/internal/v1/products/${id}/active`, data })

export const getProduct = (id) => axios.get(`${baseUrl}/api/product/${id}`)

export const getProductLabel = (productIds) =>
  Get({
    url: '/api/product-label',
    params: { pageIndex: 0, pageSize: 1000, productIds },
  })

export const getDetailDiscoveryLabel = (labelId) =>
  Get({
    url: '/api/discovery-label/:labelId',
    urlParams: { labelId },
  })

export const deleteLabelOnProductApi = (productId, labelId) =>
  Delete({
    url: '/api/product-label/product/:productId/label/:labelId',
    urlParams: { productId, labelId },
  })

// export const putProduct = (id, payload) => axios.put(`${baseUrl}/api/product/${id}`, payload)
export const putProduct = (id, data) =>
  Put({
    url: '/api/ims/:version/products/:id',
    version: 'v2',
    urlParams: { id },
    data,
  })

export const getInventory = (params) => axios.get(`${baseUrl}/api/inventories/v4`, { params })
export const getInventoryV2 = (params) => axios.get(`${baseUrl}/api/ims/v2/inventories`, { params })

export const getInventoriesProduct = (params) =>
  axiosInstanceApiGateway.Get({ url: `/ims/internal/v1/products/new-inventory`, params })

export const getHubs = () => axios.get(`${baseUrl}/api/locations/hub`)

export const getLocationsByType = (type) => axios.get(`${baseUrl}/api/locations/${type}`)

export const getOrderLocation = () => axios.get(`${baseUrl}/api/locations/order`)

export const getAlllocation = () => axios.get(`${baseUrl}/api/locations/all`)

export const getLocByProduct = (id) => axios.get(`${baseUrl}/api/inventory/product-locations/${id}`)

export const postInventory = (payload) => axios.post(`${baseUrl}/api/inventory`, payload)

export const putInventoryBulkUpdatePrice = (payload) =>
  axios.put(`${baseUrl}/api/inventory/bulk-price`, payload)

export const putLocationInventoryActive = (locId, proId, payload) =>
  axios.put(`${baseUrl}/api/inventory/${locId}/${proId}/active`, payload)

export const putLocationInventory = (locId, proId, payload) =>
  axios.put(`${baseUrl}/api/inventory/${locId}/${proId}`, payload)

export const getWarehouse = () => axios.get(`${baseUrl}/api/locations/warehouse`)

export const getInventoriesWarehouse = (query) =>
  axios.get(`${baseUrl}/api/inventories/v2/warehouse`, { params: query })

export const getSupplyOrderProducts = (id, query = '') =>
  axios.get(`${baseUrl}/api/supply-order/product/${id}${query}`)

export const postSupplyOrder = (payload) => axios.post(`${baseUrl}/api/supply-order`, payload)

export const getSupplyOrder = (query = '') => axios.get(`${baseUrl}/api/supply-order${query}`)

export const getInbounds = (query = '') => axios.get(`${baseUrl}/api/inbound${query}`)

export const getInbound = (id = '') => axios.get(`${baseUrl}/api/inbound/${id}`)

export const putInbound = (id = '', payload) => axios.put(`${baseUrl}/api/inbound/${id}`, payload)

export const getPrintSupplyOrder = (id = '') => axios.get(`${baseUrl}/api/supply-order/${id}/file`)

export const getPrintInbound = (id = '') => axios.get(`${baseUrl}/api/inbound/${id}/file`)

// DRIVER API'S
export const getDriverList_Driver = (query = '') => axios.get(`${baseUrl}/api/driver${query}`)

export const postNewDriver_Driver = (data) => axios.post(`${baseUrl}/api/driver`, data)

export const putDriver_Driver = (id, data) => axios.put(`${baseUrl}/api/driver/${id}`, data)

export const putDriverActive_Driver = (id, dataStatus) =>
  axios.put(`${baseUrl}/api/driver/${id}/active`, dataStatus)

export const getDriverAvailable = (locationId, role, params) =>
  axios.get(`${baseUrl}/api/staff-management/staff/${locationId}/AUTH_${role}`, { params })
// END DRIVER API's

// BUYER API's
export const getBuyerList_Buyer = (query = '') => axios.get(`${baseUrl}/api/customer${query}`)

export const putActiveBuyer_Buyer = (id, dataStatus) =>
  axios.put(`${baseUrl}/api/customer/${id}/active`, dataStatus)
// END BUYER API's

export const getSupplyOrderDetail = (id = '') => axios.get(`${baseUrl}/api/supply-order/${id}`)

export const putSupplyOrder = (id = '', payload) =>
  axios.put(`${baseUrl}/api/supply-order/${id}`, payload)

export const putSupplyOrderConfirm = (id = '', payload) =>
  axios.put(`${baseUrl}/api/supply-order/${id}/confirm`, payload)

export const getPickers = (params) => axios.get(`${baseUrl}/api/picker-packer-order`, { params })

export const getPickersSuper = (params) =>
  axios.get(`${baseUrl}/api/picker/super/order`, { params })

export const getPicker = (id) => axios.get(`${baseUrl}/api/picker/order/${id}`)

export const getPickerSuper = (id) => axios.get(`${baseUrl}/api/picker/super/order/${id}`)

export const putPicker = (id, payload) =>
  axios.put(`${baseUrl}/api/picker/order/${id}/picking`, payload)

export const putPickerSuper = (id, payload) =>
  axios.put(`${baseUrl}/api/picker/super/order/${id}/picking`, payload)

export const changePicker = (id, payload) =>
  axios.put(`${baseUrl}/api/picker/order/${id}/change-picker`, payload)
export const changePickerSuper = (id, payload) =>
  axios.put(`${baseUrl}/api/picker/super/order/${id}/change-picker`, payload)

export const changePacker = (id, payload) =>
  axios.put(`${baseUrl}/api/packer/order/${id}/change-packer`, payload)
export const changePackerSuper = (id, payload) =>
  axios.put(`${baseUrl}/api/packer/super/order/${id}/change-packer`, payload)

export const cancelPicker = (id, data) =>
  axios.put(`${baseUrl}/api/picker/order/${id}/cancel`, data)

export const returnPicker = (id) => axios.put(`${baseUrl}/api/picker/order/${id}/return`)

export const getPackers = (params) => axios.get(`${baseUrl}/api/picker-packer-order`, { params })

export const getOrdersV2 = (params) => axios.get(`${baseUrl}/api/picker-packer-order`, { params })

export const getPackersSuper = (params) =>
  axios.get(`${baseUrl}/api/packer/super/order`, { params })

export const putPacker = (id) => axios.put(`${baseUrl}/api/picker/order/${id}/packing`)

export const getPacker = (id) => axios.get(`${baseUrl}/api/packer/order/${id}`)

export const getPackerInvoice = (query) => axios.get(`${baseUrl}/api/packer/order/detail${query}`)
export const getPackerOrderId = (query) => axios.get(`${baseUrl}/api/packer/order/detail${query}`)
export const getPackerOrderIdSuper = (orderId, query) => {
  let id = orderId.orderId ? orderId.orderId : orderId
  return axios.get(`${baseUrl}/api/packer/super/order/${id}/item${query}`)
}

export const postPackerInvoice = (query) => axios.post(`${baseUrl}/api/packer/order/packing`, query)

export const postPackerInvoiceSuper = (query) =>
  axios.post(`${baseUrl}/api/packer/super/order/${query.order_id}/packing`, query)

export const getTimeslotList = (query) => axios.get(`${baseUrl}/api/order/timeslots${query}`)

export const putPackerQty = (id, payload) =>
  axios.put(`${baseUrl}/api/packer/order/${id}/confirm-packing`, payload)

export const putPackerQtySuper = (id, payload) =>
  axios.put(`${baseUrl}/api/packer/super/order/${id}/confirm-packing`, payload)

export const cancelAutoAssign = (id, payload) =>
  axios.put(`${baseUrl}/api/packer/order/${id}/cancel-driver`, payload)
export const cancelPacker = (id, data) =>
  axios.put(`${baseUrl}/api/packer/order/${id}/cancel`, data)

export const getSkuManualInputNotes = (params) =>
  axios.get(`${baseUrl}/api/sku-manual-input-notes`, { params })

export const postSkuManualInputAuth = (payload) =>
  axios.post(`${baseUrl}/api/sku-manual-input-auth`, payload)

export const getDrivers = (id) => axios.get(`${baseUrl}/api/driver-admin/available/${id}`)
export const getDriversSuper = (id, query) =>
  axios.get(`${baseUrl}/api/driver-admin/available/${id}/SUPER_DRIVER${query}`)

export const putCallDriver = (id, payload) =>
  axios.put(`${baseUrl}/api/packer/order/${id}/assign-driver`, payload)

export const putChangeDriver = (id, payload) =>
  axios.put(`${baseUrl}/api/packer/order/${id}/change-driver`, payload)

export const putPickupOrder = (id) => axios.put(`${baseUrl}/api/packer/order/${id}/pick-up`)

export const returnPacker = (id) => axios.put(`${baseUrl}/api/packer/order/${id}/return`)

export const listPacker = (id) => axios.get(`${baseUrl}/api/packer/order/${id}/print-list`)

export const listP1cker = (id) => axios.get(`${baseUrl}/api/picker/order/${id}/print-list`)

export const listP1ckerSuper = (id, params) =>
  axios.get(`${baseUrl}/api/picker/super/order/${id}/item`, { params })

export const putUpcomingOrder = (id, payload) =>
  Put({ url: `/api/picker/order/${id}/upcoming-to-new-order`, data: payload })

export const resiPacker = (id) => axios.get(`${baseUrl}/api/packer/order/${id}/print-receipt`)

export const detailOrder = (id) => axios.get(`${baseUrl}/api/packer/order/${id}`)

export const getSettingsData = () => axios.get(`${baseUrl}/api/order-setting`)

export const putSettingsData = (id, data) => axios.put(`${baseUrl}/api/order-setting/${id}`, data)

export const getTickers = (params) => axios.get(`${baseUrl}/api/ticker`, { params })

export const postTicker = (payload) => axios.post(`${baseUrl}/api/ticker`, payload)

export const putTicker = (id, payload) => axios.put(`${baseUrl}/api/ticker/${id}`, payload)

export const getTicker = (id) => axios.get(`${baseUrl}/api/ticker/${id}`)

export const postPickerPooled = (payload) =>
  axios.post(`${baseUrl}/api/picker/order/pooled`, payload)

export const postPickerTagPool = (payload) =>
  axios.post(`${baseUrl}/api/picker/order/tag-pool`, payload)

export const postPickerUntagPool = (payload) =>
  axios.post(`${baseUrl}/api/picker/order/untag-pool`, payload)

export const postPackerPooled = (payload) =>
  axios.post(`${baseUrl}/api/packer/order/pooled`, payload)

export const postPackerTagPool = (payload) =>
  axios.post(`${baseUrl}/api/packer/order/tag-pool`, payload)

export const postPackerUntagPool = (payload) =>
  axios.post(`${baseUrl}/api/packer/order/untag-pool`, payload)

export const putTickerStatus = (id, payload) =>
  axios.put(`${baseUrl}/api/ticker/${id}/active`, payload)

export const getPages = () => axios.get(`${baseUrl}/api/funnel-pages`)

export const getAppLinks = () => axios.get(`${baseUrl}/api/app-link`)

export const getBenefits = () => axios.get(`${baseUrl}/api/voucher/benefit`)
export const getTargets = () => axios.get(`${baseUrl}/api/voucher/targeted`)

export const getPaymentChannel = () => axios.get(`${consumerAPI}/api/internal/payment-channel`)

export const getMechanisms = () => axios.get(`${baseUrl}/api/voucher/mechanism`)

export const getCustomer = (params) => axios.get(`${baseUrl}/api/customer/search`, { params })

export const getRefferalSettingBuyer = () => axios.get(`${baseUrl}/api/referral-setting-buyer`)

export const putRefferalSettingBuyer = (id, payload) =>
  axios.put(`${baseUrl}/api/referral-setting-buyer/${id}`, payload)

export const postVoucher = (payload) => axios.post(`${baseUrl}/api/voucher`, payload)

export const getVoucher = (params) => axios.get(`${baseUrl}/api/voucher`, { params })

export const putVoucherActive = (id, payload) =>
  axios.put(`${baseUrl}/api/voucher/${id}/active`, payload)

export const getVoucherDetail = (id) => axios.get(`${baseUrl}/api/voucher/${id}`)

export const putVoucherDetail = (id, payload) => axios.put(`${baseUrl}/api/voucher/${id}`, payload)

export const getOrderSettingDetail = () => axios.get(`${baseUrl}/api/order-setting-detail`)

export const putOrderSetting = (id, payload) =>
  axios.put(`${baseUrl}/api/order-setting-detail/${id}`, payload)

export const postUploadedTargettedUSer = (payload) =>
  axios.post(`${baseUrl}/api/voucher/csv/upload-user`, payload)

export const postUom = (payload) => axios.post(`${baseUrl}/api/uoms`, payload)
export const getUoms = (params) =>
  axios.get(`${baseUrl}/api/uoms`, { params: { ...params, pageSize: 50 } })
export const putUom = (id, payload) => axios.put(`${baseUrl}/api/uoms/${id}/toggle`, payload)
export const putUomData = (id, payload) => axios.put(`${baseUrl}/api/uoms/${id}`, payload)
export const getUomsExport = (params) => axios.get(`${baseUrl}/api/uoms/export`, { params })
export const getUomGroups = () => axios.get(`${baseUrl}/api/uoms/groups`)
export const getProductPackageStructure = (params) =>
  axios.get(`${baseUrl}/api/product-package-structure`, { params })
export const putProductPackageStructure = (payload) =>
  axios.put(`${baseUrl}/api/product-package-structure`, payload)
export const deleteProductPackageStructure = (id) =>
  axios.delete(`${baseUrl}/api/product-package-structure/${id}`)
export const getRackStorage = (params = {}) => axios.get(`${baseUrl}/api/racks/storage`, { params })

export const getPutAway = () => axios.get(`${baseUrl}/api/uoms`)
export const getGrn = () => axios.get(`${baseUrl}/api/uoms`)
export const getDamagePutAway = () => axios.get(`${baseUrl}/api/uoms`)
export const getReceiving = () => axios.get(`${baseUrl}/api/uoms`)
export const getVendor = () => axios.get(`${baseUrl}/api/uoms`)

export const getPurchaseOrders = (params) =>
  axios.get(`${baseUrl}/erp/api/purchase-orders`, { params })

export const cancelPurchaseOrders = (id) =>
  axios.get(`${baseUrl}/erp/api/purchase-orders/cancel/${id}`)

export const closedPurchaseOrders = (id) =>
  axios.get(`${baseUrl}/erp/api/purchase-orders/close/${id}`)

export const detailPurchaseOrder = (id, params) =>
  axios.get(`${baseUrl}/erp/api/purchase-orders/${id}`, { params })

export const putPurchaseOrder = (payload, id) =>
  axios.put(`${baseUrl}/erp/api/purchase-orders/${id}`, payload)

export const getPartiesPurchaseOrder = (params) =>
  axios.get(`${baseUrl}/erp/api/purchase-orders/order-parties`, { params })
export const postPartiesPurchaseOrder = (payload) =>
  axios.post(`${baseUrl}/erp/api/purchase-orders/order-parties`, payload)

export const deletePartiesPurchaseOrder = (id) =>
  axios.delete(`${baseUrl}/erp/api/purchase-orders/order-parties/${id}`)

export const putPartyPurchaseOrder = (id, payload) =>
  axios.put(`${baseUrl}/erp/api/purchase-orders/order-parties/${id}`, payload)

export const getVendorPo = () => axios.get(`${baseUrl}/erp/api/vendor`)

export const getPurchaseOrdersLiteV2 = (params) =>
  axios.get(`${baseUrl}/api/wms/v2/purchase-order`, { params })

export const getPurchaseOrdersReference = (params) =>
  axios.get(`${baseUrl}/api/purchase-order/reference`, { params })

export const createPoReference = (poId) =>
  Post({
    url: '/api/wms/:version/purchase-order/reference/:poId',
    version: 'v1',
    urlParams: { poId },
  })

export const getVendorPurchaseOrder = (params) =>
  axios.get(`${baseUrl}/api/purchase-order/vendor`, { params })

export const getVendorList = (params) =>
  axiosInstanceApiGateway.Get({
    url: `/erp/internal/v2/vendors`,
    params,
  })

export const postPurchaseOrderItemScan = (poId, data) =>
  axiosInstanceApiGateway.Post({ url: `/wms/internal/v1/purchase-order/${poId}/item-scan`, data })

export const putPurchaseOrderLite = (id, payload) =>
  axios.put(`${baseUrl}/api/purchase-order/${id}`, payload)

export const getPurchaseOrderCsvTemplate = () =>
  axios.get(`${baseUrl}/api/purchase-order/csv/template`)

export const postPurchaseOrderCsv = (payload) =>
  axios.post(`${baseUrl}/api/purchase-order/csv`, payload)

export const postPurchaseOrderStatus = (id, status) =>
  axios.put(`${baseUrl}/api/purchase-order/${id}/status?status=${status}`)

export const getInboundPo = (params) =>
  axios.get(`${baseUrl}/api/purchase-order/inbound`, { params })

export const getPurchaseOrderDetailLiteInbound = (id, query) =>
  axios.get(`${baseUrl}/api/purchase-order/inbound/${id}${query}`)

export const putPurchaseOrderDetailLiteInbound = (id, payload) =>
  axios.put(`${baseUrl}/api/wms/v2/purchase-order/inbound/${id}`, payload)

export const getInboundPoDataPrint = (id) => axios.get(`${baseUrl}/api/purchase-order/${id}/grn`)

export const getRacksExport = (params) => {
  return axios.get(`${baseUrl}/api/racks/export`, { params })
}

export const getSupplyOrderRemarks = () =>
  Get({
    url: '/api/wms/v2/supply-order/remarks',
  })

export const getSupplyOrderGrand = (params) =>
  axios.get(`${baseUrl}/api/supply-order-grand`, { params })

export const getSupplyOrderGrandV2 = (params) =>
  Get({ url: '/api/wms/:version/supply-order', version: 'v2', params })

export const getSupplyOrderType = () => Get({ url: '/api/wms/v2/supply-order/type' })

export const getSupplyOrderGrandProduct = (id, params) =>
  axios.get(`${baseUrl}/api/supply-order-grand/product/${id}`, {
    params,
  })

export const startSoLoadingActivity = (data) =>
  apiServiceApiGateway.Put({ url: '/wms/internal/v1/supply-order/loading/start-activity', data })

export const postSupplyOrderGrand = (payload) =>
  axios.post(`${baseUrl}/api/wms/v2/supply-order`, payload)

export const getSupplyOrderGrandDetail = (id) =>
  axios.get(`${baseUrl}/api/supply-order-grand/${id}`)

export const getSupplyOrderGrandDetailItem = (id, params) =>
  axios.get(`${baseUrl}/api/supply-order-grand/${id}/item`, { params })

export const putSupplyOrderGrand = (payload, id) =>
  axios.put(`${baseUrl}/api/wms/v2/supply-order/${id}`, payload)

export const getSupplyOrderGrandData = (id, params) =>
  axios.get(`${baseUrl}/api/supply-order-grand/${id}/data`, { params })

export const getPrintSupplyOrderGroup = (id) =>
  axios.get(`${baseUrl}/api/wms/v1/supply-order/loading/${id}/file`)

export const putSupplyOrderGrandStatus = (id, payload) =>
  Put({
    url: '/api/wms/:version/supply-order/:id/status',
    version: 'v2',
    urlParams: { id },
    data: payload,
  })

export const postStartUnloadingActivity = (unloadingId) =>
  Post({
    url: '/api/wms/:version/supply-order/unloading/start/:unloadingId',
    version: 'v1',
    urlParams: { unloadingId },
    data: {
      device_type: 'WEB',
    },
  })

export const postSupplyOrderGrandCopy = (id) =>
  axios.post(`${baseUrl}/api/supply-order-grand/${id}/copy`)

export const getSupplyOrderGrandPacking = (id) =>
  axios.get(`${baseUrl}/api/supply-order-grand/${id}/packing`)

export const getSupplyOrderDeliveryDocument = (query) =>
  axios.get(`${baseUrl}/api/supply-order-grand/delivery-document${query}`)

export const getSupplyOrderDeliveryDocumentLoadingV2 = (query) =>
  Get({
    url: `/api/wms/:version/supply-order/print-delivery-document${query}`,
    version: 'v2',
  })

export const getSupplyOrderDeliveryDocumentLoading = (id) =>
  axios.get(`${baseUrl}/api/wms/v2/supply-order/print-delivery-document/${id}`)

export const putSupplyOrderGrandPacking = (id, payload) =>
  axios.put(`${baseUrl}/api/supply-order-grand/${id}/packing`, payload)

export const getInboundSoV2 = (params) =>
  Get({ url: '/api/wms/:version/supply-order-inbound', version: 'v2', params })

export const getManufacturingOrder = (params) =>
  axiosInstanceApiGateway.Get({ url: '/ims/internal/v1/manufacturing/order', params })

export const getInboundSOCheckQty = (id) =>
  axiosInstanceApiGateway.Get({
    url: `/wms/internal/v1/supply-order/${id}/inbound/check-quantity`,
  })

export const getInboundsSOItem = (id, params) =>
  Get({
    url: `/api/wms/v3/supply-order-inbound/${id}/item/product`,
    urlParams: { id },
    params,
  })

export const putInboundsSOItem = (id, payload) =>
  axios.put(`${baseUrl}/api/wms/v2/supply-order-inbound/${id}`, payload)

export const getGrnData = (id) => axios.get(`${baseUrl}/api/inbound-grand/${id}/grn`)

export const postInboundPutaway = (status, payload) =>
  axios.post(`${baseUrl}/api/inbound-grand/putaway/${status}`, payload)

export const postValidateCheckQty = (id, payload) =>
  axios.post(`${baseUrl}/api/supply-order-grand/${id}/packing/validate`, payload)

export const getInventoryStatus = (params) =>
  axios.get(`${baseUrl}/api/inventory-status-notes`, { params })

export const getInventoryStatusNotesSo = (params) =>
  axios.get(`${baseUrl}/api/inventory-status-notes/so`, { params })

export const getScanManualReason = () =>
  axios.get(`${baseUrl}/api/supply-order-grand/manual-reason`)

export const getStatus = () => axios.get(`${baseUrl}/api/inventory-status`)

export const putExpiryDateInboundItem = (data) =>
  axiosInstanceApiGateway.Put({
    url: `/wms/internal/v1/supply-order/inbound/item/expiry-date`,
    data,
  })

export const postValidateInbound = (id, payload, isHubFeatureEdDryScanActive = false) =>
  Post({
    url: postValidateInboundEndpoint(isHubFeatureEdDryScanActive),
    urlParams: { id },
    data: payload,
  })

export const postSubmitInboundItem = (id, payload) =>
  axiosInstanceApiGateway.Post({
    url: `/wms/internal/v1/supply-order/:id/inbound/item`,
    urlParams: { id },
    data: payload,
  })

export const getInboundItemEdit = (params) =>
  axios.get(`${baseUrl}/api/inbound-grand/item/edit`, { params })

export const putInboundItemEdit = (payload) =>
  axios.put(`${baseUrl}/api/inbound-grand/item/edit`, payload)

export const deleteInboundItem = (queryParams) =>
  apiServiceApiGateway.Delete({
    url: `/wms/internal/v1/supply-order/inbound/item${queryParams}`,
  })

export const getRacks = (id, params) => axios.get(`${baseUrl}/api/racks/${id}`, { params })

export const putRackInboundItem = (data) =>
  apiServiceApiGateway.Put({ url: '/wms/internal/v1/supply-order/inbound/item/rack', data })

export const getAllStatusSource = () => axios.get(`${baseUrl}/api/product/status-source`)

export const getAllStorageHandling = () => axios.get(`${baseUrl}/api/product/storage-handling`)

export const getAllBrand = ({ params }) => axios.get(`${baseUrl}/api/brand`, { params })

export const getAllBrandV2 = ({ params }) => axios.get(`${baseUrl}/api/v2/brand`, { params })

export const getAllBOMCategory = () => axios.get(`${baseUrl}/api/product/bom-category`)
export const getOrderKit = (params) => axios.get(`${baseUrl}/api/order-kit`, { params })

export const postCatalogue = (payload) => axios.post(`${baseUrl}/api/catalogue`, payload)
export const getOrderKitComponents = (id) => axios.get(`${baseUrl}/api/order-kit/${id}/components`)

export const getOrderKitDetail = (id) => axios.get(`${baseUrl}/api/order-kit/${id}`)

export const postOrderKit = (patload) => axios.post(`${baseUrl}/api/order-kit`, patload)

export const getFullfllmentOrderKit = (id) =>
  axios.get(`${baseUrl}/api/order-kit/${id}/fulfillments`)

export const cancelOrderKit = (id) => axios.put(`${baseUrl}/api/order-kit/${id}/cancellation`)

export const confirmOrderKit = (id) => axios.put(`${baseUrl}/api/order-kit/${id}/confirmation`)

export const putOrderKit = (payload, id) => axios.put(`${baseUrl}/api/order-kit/${id}`, payload)

export const getKittingPickingList = (id) =>
  axios.get(`${baseUrl}/api/order-kit/${id}/picking-list`)

export const getCheckQtyKitting = (id) => axios.get(`${baseUrl}/api/order-kit/${id}/checking-list`)

export const pickOrderKit = (id) => axios.put(`${baseUrl}/api/order-kit/${id}/pick`)

export const postQtyValidate = (id, payload) =>
  axios.post(`${baseUrl}/api/order-kit/${id}/qty-validation`, payload)

export const postDetailBulk = (payload) => axios.post(`${baseUrl}/api/product-detail/bulk`, payload)

export const postOrderKitActual = (id, payload) =>
  axios.post(`${baseUrl}/api/order-kit/${id}/actual-qty`, payload)

export const putOrderKitStatus = (id) => axios.put(`${baseUrl}/api/order-kit/${id}/kit`)

export const putOrderKitCompletion = (id, payload) =>
  axios.put(`${baseUrl}/api/order-kit/${id}/completion`, payload)

export const putOrderKitSubmit = (id, payload) =>
  axios.put(`${baseUrl}/api/order-kit/${id}/submit`, payload)

export const getCatalogs = (params) => axios.get(`${baseUrl}/api/catalogue`, { params: params })

export const putCatalogue = (id, payload) => axios.put(`${baseUrl}/api/catalogue/${id}`, payload)

export const getLabelOrderProduct = ({ orderID, productID }) =>
  axios.get(`${baseUrl}/api/label/${orderID}/${productID}`)

export const getDataGeneratedKoli = (params) => axios.get(`${baseUrl}/api/kolis`, { params })

export const getDetailDataGeneratedKoli = (date, params) =>
  axios.get(`${baseUrl}/api/kolis/${date}`, { params })

export const postGenerateKoli = (payload) => axios.post(`${baseUrl}/api/kolis`, payload)
export const putBrandCloudKitchen = (payload) =>
  axios.put(`${baseUrl}/api/brand/cloud-kitchen`, payload)

export const getBrandCompany = (params) => axios.get(`${baseUrl}/api/brand-companies`, { params })

export const postBrand = (payload) => axios.post(`${baseUrl}/api/brand`, payload)

export const putBrand = (payload) => axios.put(`${baseUrl}/api/brand`, payload)
export const getTargetingType = () => axios.get(`${baseUrl}/api/targeting-type`)

export const getCatalogueById = (id) => axios.get(`${baseUrl}/api/catalogue/${id}`)

export const getSupplyOrderGrandKoliLabel = (id) =>
  axios.get(`${baseUrl}/api/v2/supply-order-grand/${id}/koli-label`)

export const downloadLocationReRouteLog = (id, startDate, endDate) =>
  axios.get(`${baseUrl}/api/location/${id}/history/reroute`, {
    responseType: 'blob',
    params: {
      start_date: startDate,
      end_date: endDate,
    },
  })

export const getFeatureAndServiceIdBulkUpload = (params) =>
  axios.get(`${baseUrl}/api/bulk-upload/service-feature`, { params })
export const getLocationType = () => axios.get(`${baseUrl}/api/location/type`)
export const getMapPlaceDetail = (params) => Get({ url: `/api/maps/place-detail`, params })
export const getLocationCloseReason = () => Get({ url: `/api/location/close-reason` })
export const getInProgressPicking = (id) =>
  Get({ url: `/api/supply-order-grand/${id}/in-progress-picking` })

export const getLocationPoolingConfig = (locationId) =>
  axiosInstanceApiGateway.Get({ url: `/oms/internal/v1/pooling_configurations/${locationId}` })
export const getCombinationSLA = (params) =>
  Get({
    url: `/api/pooling-configuration/sla-combination?combination=${params}`,
  })
export const putSavePoolingConfig = (payload) =>
  axiosInstanceApiGateway.Put({
    url: `/oms/internal/v1/pooling_configuration`,
    data: payload,
  })
export const postNewPeakHourPoolingConfig = (payload) =>
  axiosInstanceApiGateway.Post({
    url: `/oms/internal/v1/pooling_configuration/peak_time`,
    data: payload,
  })
export const putNewPeakHourPoolingConfig = (payload) =>
  axiosInstanceApiGateway.Put({
    url: `/oms/internal/v1/pooling_configuration/peak_time`,
    data: payload,
  })

export const getReasonsForSupplyOrder = (params) =>
  Get({ url: `/api/wms/:version/purchase-order/reasons`, version: 'v2', params })

export const fetchOrderDetailV2 = (id) => Get({ url: `/api/v2/order/${id}` })
export const fetchOrderProducts = (id) => Get({ url: `/api/v2/order/${id}/products` })
export const fetchOrderActivities = (id) => Get({ url: `/api/v2/order/${id}/activities` })

export const getStaffManagement = (locationId, role, params = {}) =>
  axios.get(`${baseUrl}/api/staff-management/staff/${locationId}/${role}`, { params })

export const getSoConversionItems = (supplyOrderId) =>
  axiosInstanceApiGateway.Get({
    url: `/wms/internal/v1/supply-order/conversion/items/${supplyOrderId}`,
  })

export const applySoConversion = (data) =>
  axiosInstanceApiGateway.Post({
    url: '/wms/internal/v1/supply-order/conversion/task',
    data,
  })

export const getRouteMaster = (query) =>
  Get({
    url: `/api/wms/:version/route-master${objectToQueryParams(query)}`,
    version: 'v1',
  })

export const postAssignPicker = (soId, data) =>
  Post({
    url: `/api/wms/:version/supply-order/${soId}/picking/assign`,
    version: 'v2',
    data,
  })

import axiosInstanceApiGateway from 'config/apiServiceApiGateway'

export interface GetMasterPayrollListServicesInterface {
  data: {
    id: number
    staff: {
      id: number
      name: string
    }
    role: string
    employee_status: string
    payroll_type: string
    amount: string
  }[]
  pagination: {
    number_of_elements: number
    page_index: number
  }
}

export interface GetPayrollDetailByUserIdServicesInterface {
  data: {
    id: string
    staff_id: string
    staff_name: string
    payroll: {
      id: string
      start_date: string
      end_date: string
      period: string
      payroll_type: string
      total_amount: string
    }[]
  }
  pagination: {
    number_of_elements: number
    page_index: number
  }
}

export interface PayrollQueryParamsInterface {
  sort: string
  direction: string
  pageSize: number
  pageIndex: number
  numberOfElements: number
}

export const getMasterPayrollList = (
  params: Pick<PayrollQueryParamsInterface, 'direction' | 'pageIndex' | 'pageSize' | 'sort'> & {
    search?: string
    locationId: number
    year: string
    month: string
    payrollType: string
  },
) =>
  axiosInstanceApiGateway.Get<GetMasterPayrollListServicesInterface, true>({
    url: 'staff-management/internal/:version/payroll/list',
    params,
    version: 'v1',
    convertRequest: true,
  })

export const getDetailPayrollByUserIdServices = (
  params: PayrollQueryParamsInterface & { staffId: string | number },
) =>
  axiosInstanceApiGateway.Get<GetPayrollDetailByUserIdServicesInterface, true>({
    url: 'staff-management/internal/:version/payroll/detail',
    params: {
      ...params,
      published: false,
    },
    version: 'v1',
    convertRequest: true,
  })

export const getMasterPayrollPayslipPdf = (params: { payrollId: string | number }) =>
  axiosInstanceApiGateway.Get<{ data: string }, true>({
    url: 'staff-management/internal/:version/payroll/pdf',
    params,
    version: 'v1',
    convertRequest: true,
  })

export const postPublishPayslip = (payload: unknown) =>
  axiosInstanceApiGateway.Post({
    url: 'staff-management/internal/:version/payroll/publish',
    version: 'v1',
    data: payload,
    convertRequest: true,
  })

export const getExportedPayrollExcel = (data: {
  locationId: string
  year: string
  month: string
  search?: string
  payoutType?: string
}) =>
  axiosInstanceApiGateway.Post<{ data: { file_url: string } }, true>({
    url: 'staff-management/internal/:version/payroll/export',
    data,
    version: 'v1',
    convertRequest: true,
  })

export const getMasterPayrollBulkUploadTemplate = (params: {
  payoutType: string
  staffStatus: string
}) =>
  axiosInstanceApiGateway.Get<{ data: { file_url: string } }, true>({
    url: 'staff-management/internal/:version/payroll/template',
    params,
    version: 'v1',
    convertRequest: true,
  })

export const postMasterPayrollBulkUpload = (data: unknown) =>
  axiosInstanceApiGateway.Post({
    url: 'staff-management/internal/:version/payroll/import',
    data,
    version: 'v1',
    contentType: 'multipart/form-data',
  })
